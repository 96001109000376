import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { Language } from 'app/entities/types';
import { PageMetatags, PageHeader, PageContent, Spinner, LanguageSelector, useLocalization } from 'components';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { userHasAccessToRoute } from 'app/entities/methods';
import { Redirect, useLocation } from "react-router-dom";
import { updateSelectedLanguage } from 'app/session/actions'
import { useDispatch } from 'react-redux'
import { useRouter } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    position: 'fixed',
    top: 0,
    zIndex: 1
  },
  contentDefault: {
    padding: theme.spacing(3),
    marginTop: '60px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
  },
  contentFullScreen: {
    width: '100%',
    padding: 0
  },
  topBar: {
    marginBottom: theme.spacing(1),
    marginTop: '-20px',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '48px',
    alignItems: 'center'
  },
  tabsContainer: {

  },
  optionsContainer: {
    '&>button:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}))


export type PageTabProps = {
  key: string
  label?: string
}

export type PageProps = {
  isLoading?: boolean
  className?: string
  title: string
  fullScreenLayout?: boolean
  parameters?: { [key: string]: string }
  previousRoute?: string
  currentRoute?: string
  actions?: ReactNode

  tabs?: PageTabProps[]
  selectedTab?: string
  defaultTab?: string
  updateTab?: (tab: PageTabProps) => void

  language?: Language
  updateLanguage?: (language: Language) => void

  topBarActions?: ReactNode

  children?: ReactNode
}

const Page = ({ ...props }: PageProps) => {
  const classes = useStyles()
  const { t } = useLocalization()
  const session = useSelector((state: RootState) => state.session)
  const location = useLocation()
  const dispatch = useDispatch()
  const router = useRouter()

  const defaultFullScreenLayoutValue = false
  const fullScreenLayoutValue = props.fullScreenLayout ?? defaultFullScreenLayoutValue


  //LANGUAGE

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(props.language ?? session.selectedLanguage)

  function updateLanguage(language: Language) {
    if (language.id === selectedLanguage.id) return
    setSelectedLanguage(language)
  }

  useEffect(() => {
    //Updates the selected language in session.
    dispatch(updateSelectedLanguage(selectedLanguage))
    if (props.updateLanguage != null) props.updateLanguage(selectedLanguage)
  }, [selectedLanguage])

  // useEffect(() => store.subscribe(() => {
  //   //When the session is updated triggers the callback to notify the "parent" component.
  //   const lang = _.clone(session.selectedLanguage)
  // }), [])



  //TABS

  function handleTabChange(event: any, value: string) {
    if (props.tabs == null) return

    //Check if the tab is a valid one.
    const tab = props.tabs.find(t => t.key === value)
    if (tab == null) return

    if (props.updateTab != null) props.updateTab(tab)

    //Updates the route.
    router.history.push(tab.key)
  }



  //RENDER

  if (userHasAccessToRoute(session.user, location.pathname) === false) {
    return <Redirect to={'/error/403'} />
  }

  if (props.isLoading === true) {
    return (
      <div className={classes.loading} >
        <Spinner />
      </div>
    )
  }

  return (
    <div className={props.className}>
      <PageMetatags title={props.title} parameters={props.parameters} />

      {fullScreenLayoutValue === false && <PageHeader title={props.title} className={classes.header} previousRoute={props.previousRoute} actions={props.actions} />}

      <PageContent className={fullScreenLayoutValue === false
        ? classes.contentDefault
        : classes.contentFullScreen}
      >

        {(props.tabs != null || props.topBarActions != null || props.language != null) &&
          <div className={classes.topBar}>

            <div className={classes.tabsContainer}>
              {props.tabs != null &&
                <Tabs value={props.selectedTab} onChange={handleTabChange}>
                  {props.tabs!.map((tab, i) => (
                    tab.key !== '' && <Tab key={i} label={tab.label ?? t('common.page.tab.' + tab.key)} value={tab.key} />
                  ))}
                </Tabs>
              }
            </div>

            <div className={classes.optionsContainer}>
              {props.language != null &&
                <LanguageSelector languages={session.app.languages} selectedLanguage={selectedLanguage} updateLanguageCallback={updateLanguage}></LanguageSelector>
              }
            </div>
          </div>
        }

        {props.children}
      </PageContent>
    </div>
  )
}

export default Page
