/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Color } from 'theme/style';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    margin: '0px'
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    margin: '0px',
  },
  button: {
    color: Color.White,
    height: '4rem',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: -0.2,
    fontSize: '1.4rem',
    width: '100%',
    fontWeight: 500,
    borderRadius: 0,
    borderTopLeftRadius: '2.2rem',
    borderBottomLeftRadius: '2.2rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '& $icon': {
      }
    },
  },
  buttonLeaf: {
    color: 'rgba(255,255,255,0.5)',
    height: '3.3rem',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: -0.2,
    fontSize: '1.4rem',
    width: '100%',
    fontWeight: 400,
    borderTopLeftRadius: '2.2rem',
    borderBottomLeftRadius: '2.2rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '& $icon': {
      }
    },
  },
  icon: {
    color: 'rgba(255,255,255,0.3)',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    width: 30,
    fontSize: '1.8rem'
  },
  expandIcon: {
    marginLeft: 'auto',
    marginRight: 10,
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    color: theme.palette.common.white,
    fontWeight: 500,
    '& $icon': {
      color: theme.palette.common.white,
    }
  }
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
    if (props.onClick != null) props.onClick()
  };

  let marginLeft = 0;
  let paddingLeft = 20;

  if (depth > 0) {
    marginLeft = 10 + 10 * depth;
    paddingLeft = 20
  }

  const style = {
    marginLeft,
    paddingLeft
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon
              className={classes.expandIcon}
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              className={classes.expandIcon}
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open} className={classes.collapse}>{children}</Collapse>
      </ListItem>
    );
  } else {
    let itemClass = classes.itemLeaf
    let buttonClass = classes.button
    if (depth > 0) buttonClass = classes.buttonLeaf

    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
      >
        <Button
          activeClassName={classes.active}
          className={clsx(buttonClass, `depth-${depth}`)}
          component={CustomRouterLink}
          style={style}
          onClick={props.onItemClick}
          to={href}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onItemClick: PropTypes.any
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

export default NavigationListItem;
