import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, Paper, Button, Avatar, Typography, makeStyles } from '@material-ui/core';
import { Toolbar, Hidden } from '@material-ui/core';
import useRouter from 'app/utils/useRouter';
import { getInitials } from 'app/utils';
import { Navigation } from 'components';
import navigationConfig_dashboard from './config/dashboard';
import navigationConfig_website from './config/website';
import navigationConfig_training from './config/training';
import navigationConfig_admin from './config/admin';
import navigationConfig_dev from './config/dev';
import { User } from 'app/entities/types'
import { Color } from 'theme/style'
import { useSelector, connect, ConnectedProps } from 'react-redux';
import { RootState } from 'app/session/store'
import { userCan } from 'app/entities/methods'
import { UserPermissionValue } from 'app/values'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: Color.Sidebar,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      left: '-100vw',
      top: 0,
      transition: 'left 0.2s ease-in-out'
    },
  },
  rootOpen: {
    left: 0
  },
  mainNav: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  heading: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'left',
    backgroundColor: Color.White,
    height: '60px',
    minHeight: 0,
    zIndex: 999,
    '&:after': {
      content: '""',
      display: 'none',
      position: 'absolute',
      top: '100%',
      left: 0,
      height: '40px',
      width: '100%',
      background: 'linear-gradient(rgba(0,29,57,1) 0%, rgba(0,29,57,0) 100%)',
    },
    '& $a': {
      width: '100%',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& $img': {
        width: '100px',
        height: 'auto',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingTop: '30px',
    width: '100%',
    paddingBottom: 'calc(70px + ' + theme.spacing(1) + ')'
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  navigation: {
    marginTop: 0,
  },
  userBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(2),
    backgroundColor: Color.SidebarDark,
    width: '100%',
    height: 'auto',
    maxHeight: '156px',
    zIndex: 999,
    '&:after': {
      content: '""',
      display: 'none',
      position: 'absolute',
      top: '-40px',
      left: 0,
      height: '40px',
      width: '100%',
      background: 'linear-gradient(rgba(0,29,57,0) 0%, rgba(0,29,57,1) 100%)',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    height: 70,
    width: 70,
    marginBottom: theme.spacing(1)
  },
  avatarDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.common.white,
    '& $h4': {
      color: 'white',
    },
    '& $a': {
      color: 'rgba(255, 255, 255, 0.4)',
      paddingTop: '5px',
      display: 'block'
    }
  }
}));


type SidebarProps = {
  openMobile?: boolean
  onMobileClose?: any
  className: any
  onClick?: () => void
  onItemClick?: () => void
}
const Sidebar = ({ openMobile, onMobileClose, className, onClick, onItemClick, ...rest }: SidebarProps) => {
  const session = useSelector((state: RootState) => state.session)
  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname]);

  return (
    <>
      <Paper className={openMobile ? clsx(classes.root, classes.rootOpen, className) : clsx(classes.root, className)} elevation={1} square {...rest}>
        <div className={classes.mainNav}>
          <Toolbar className={classes.heading}>
            <RouterLink to="/dashboard">
              <img alt="Logo" src={'/images/common/logo.jpg'} />
            </RouterLink>
          </Toolbar>
          <div className={classes.content}>
            <nav className={classes.navigation}>

              {navigationConfig_dashboard.map(item => (
                <Navigation key={item.title} component="div" pages={item.pages} title={item.title} onClick={onClick} onItemClick={onItemClick} />
              ))}
              {navigationConfig_training.map(item => (
                <Navigation key={item.title} component="div" pages={item.pages} title={item.title} onClick={onClick} onItemClick={onItemClick} />
              ))}
              {navigationConfig_admin.map(item => (
                <Navigation key={item.title} component="div" pages={item.pages} title={item.title} onClick={onClick} onItemClick={onItemClick} />
              ))}
              {/* {navigationConfig_website.map(item => (
                <Navigation key={item.title} component="div" pages={item.pages} title={item.title} onClick={onClick} onItemClick={onItemClick} />
              ))} */}
              {userCan(session.user, UserPermissionValue.DeveloperFeatures) === true &&
                navigationConfig_dev.map(item => (
                  <Navigation key={item.title} component="div" pages={item.pages} title={item.title} onClick={onClick} onItemClick={onItemClick} />
                ))
              }

            </nav>
          </div>
        </div>
        <Toolbar className={classes.userBar}>
          <div className={classes.avatarContainer}>
            <Avatar className={classes.avatar} src={session.user.avatar}>
              {getInitials(session.user.firstname + ' ' + session.user.lastname)}
            </Avatar>
            <div className={classes.avatarDetails}>
              <Typography variant="h4">{session.user.firstname} {session.user.lastname}</Typography>
              <Typography variant="body2"><RouterLink to="/auth/logout">Logout</RouterLink></Typography>
            </div>
          </div>
        </Toolbar>
      </Paper>
    </>
  );
};

export default Sidebar
