import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from 'views/layouts/Auth'
import ErrorLayout from 'views/layouts/Error'
import SidebarLayout from 'views/layouts/Sidebar'
import DefaultLayout from 'views/layouts/Default'
import InitialView from 'views/screens/core/Dashboard'

const routes = [

  //ROOT

  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },


  //AUTHENTICATION

  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      { path: '/auth/login', exact: true, component: lazy(() => import('views/screens/core/Login')) },
      { path: '/auth/reset-password', exact: true, component: lazy(() => import('views/screens/core/ResetPassword')) },
      { path: '/auth/logout', exact: true, component: lazy(() => import('views/screens/core/Logout')) },
      { component: () => <Redirect to='/error/404' /> }
    ]
  },



  //ERRORS

  {
    path: '/error', component: ErrorLayout, routes: [
      { path: '/error/401', exact: true, component: lazy(() => import('views/screens/errors/Error401')) },
      { path: '/error/403', exact: true, component: lazy(() => import('views/screens/errors/Error403')) },
      { path: '/error/404', exact: true, component: lazy(() => import('views/screens/errors/Error404')) },
      { path: '/error/500', exact: true, component: lazy(() => import('views/screens/errors/Error500')) },
      { component: () => <Redirect to='/error/404' /> }
    ]
  },



  //VIEWS

  {
    route: '*', component: SidebarLayout, routes: [
      { path: '/dashboard', exact: true, component: InitialView, },

      //Website.
      {
        path: '/website', component: DefaultLayout, routes: [

          //Pages.
          { path: '/website/pages', exact: true, component: lazy(() => import('views/screens/core/Pages')) },
          { path: '/website/pages/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/PageDetails')) },

          //Dictionary.
          { path: '/website/dictionary', exact: true, component: lazy(() => import('views/screens/core/Dictionary')) },
          { path: '/website/dictionary/:id', exact: true, component: lazy(() => import('views/screens/core/DictionaryDetails')) },
        ]
      },

      //Training.
      {
        path: '/app', component: DefaultLayout, routes: [

          { path: '/app/pharmacies', exact: true, component: lazy(() => import('views/screens/project/Pharmacies')), },
          { path: '/app/pharmacies/:id', exact: true, component: lazy(() => import('views/screens/project/PharmacyDetails')) },
          { path: '/app/pharmacies/:id/orders/:orderId', exact: true, component: lazy(() => import('views/screens/project/OrderDetails')) },
          { path: '/app/zentiva-contacts', exact: true, component: lazy(() => import('views/screens/project/ZentivaContacts')), },
          { path: '/app/zentiva-contacts/:id', exact: true, component: lazy(() => import('views/screens/project/ZentivaContactDetails')) },
          { path: '/app/tasks', exact: true, component: lazy(() => import('views/screens/project/Trainings')), },
          { path: '/app/tasks/:id', exact: true, component: lazy(() => import('views/screens/project/TrainingDetails')) },
        ]
      },


      //Admin.
      {
        path: '/admin', component: DefaultLayout, routes: [
          { path: '/admin/users', exact: true, component: lazy(() => import('views/screens/core/Users')) },
          { path: '/admin/users/:id/:tab?', exact: true, component: lazy(() => import('views/screens/core/UsersDetails')) },
          { component: () => <Redirect to='/' /> }
        ]
      },

      //Dev.
      {
        path: '/dev', component: DefaultLayout, routes: [

          //Pages content management.
          { path: '/dev/pages/:id/manage', exact: true, component: lazy(() => import('views/screens/dev/DevPageSectionsManager')) },

          //Regions.
          { path: '/dev/pages/regions', exact: true, component: lazy(() => import('views/screens/dev/DevRegions')) },
          { path: '/dev/pages/regions/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevRegionDetails')) },

          //Provinces.
          { path: '/dev/pages/provinces', exact: true, component: lazy(() => import('views/screens/dev/DevProvinces')) },
          { path: '/dev/pages/provinces/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevProvinceDetails')) },

          //Cities.
          { path: '/dev/pages/cities', exact: true, component: lazy(() => import('views/screens/dev/DevCities')) },
          { path: '/dev/pages/cities/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevCityDetails')) },

          //Medial library folders.
          { path: '/dev/media-library/folders', exact: true, component: lazy(() => import('views/screens/dev/DevFolders')) },
          { path: '/dev/media-library/folders/:id', exact: true, component: lazy(() => import('views/screens/dev/DevFolderDetails')) },

          //Page types.
          { path: '/dev/types/page', exact: true, component: lazy(() => import('views/screens/dev/DevPageTypes')) },
          { path: '/dev/types/page/:id', exact: true, component: lazy(() => import('views/screens/dev/DevPageTypesDetails')) },

          //Field Groups.
          { path: '/dev/types/field-group', exact: true, component: lazy(() => import('views/screens/dev/DevFieldGroupTypes')) },
          { path: '/dev/types/field-group/:id', exact: true, component: lazy(() => import('views/screens/dev/DevFieldGroupTypesDetails')) },

          //Fields.
          { path: '/dev/types/field', exact: true, component: lazy(() => import('views/screens/dev/DevFieldTypes')) },
          { path: '/dev/types/field/:id', exact: true, component: lazy(() => import('views/screens/dev/DevFieldTypesDetails')) },

          //Tags.
          { path: '/dev/tags', exact: true, component: lazy(() => import('views/screens/dev/DevTags')) },
          { path: '/dev/tags/:id', exact: true, component: lazy(() => import('views/screens/dev/DevTagsDetails')) },

          //User permissions.
          { path: '/dev/users/roles', exact: true, component: lazy(() => import('views/screens/dev/DevRoles')) },
          { path: '/dev/users/roles/:id', exact: true, component: lazy(() => import('views/screens/dev/DevRolesDetails')) },

          //User permissions.
          { path: '/dev/users/permissions', exact: true, component: lazy(() => import('views/screens/dev/DevPermissions')) },
          { path: '/dev/users/permissions/:id', exact: true, component: lazy(() => import('views/screens/dev/DevPermissionsDetails')) },

          { component: () => <Redirect to='/' /> }
        ]
      },

      { component: () => <Redirect to='/error/404' /> }
    ]
  },
]

export default routes