import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { API_Appointment } from 'app/api/project/appointments'
import { API_Pharmacy } from 'app/api/project/pharmacies'



//ENDPOINTS

enum APIEndpoint {
  GetAllTrainings = 'api/trainings',
  GetTrainingById = 'api/trainings/getById',
  CreateTraining = 'api/trainings/create',
  BulkCreateTraining = 'api/trainings/groupCreate',
  UpdateTraining = 'api/trainings/update',
  DeleteTraining = 'api/trainings/delete',
}



//API TYPES

export type API_Training = {
  id: number
  adhesionNumber?: number
  trainingStatus?: {
    id: number
    keyword: string
    name: string
  }
  pharmacy?: API_Pharmacy
  assignee?: {
    id: number,
    firstname: string
    lastname: string
  } | null
  notes?: string
  appointments?: API_Appointment[]
  nextAppointment?: API_Appointment
}



//REQUESTS

export interface GetAllTrainingsRequestParams extends APIPaginatable, APISortable, APISearchable {
  assigneeId?: number
}
export interface GetAllTrainingsResponse extends APIPaginatableResponse {
  trainings: API_Training[]
}
enum GetAllTrainingsResponseError {
}
export async function getAllTrainings(params: GetAllTrainingsRequestParams, output?: RequestOutput<GetAllTrainingsResponse, GetAllTrainingsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllTrainings,
    params: params,
    output: output,
  })
}



export type GetTrainingByIdRequestParams = {
  id: number
}
export type GetTrainingByIdResponse = {
  training: API_Training
}
enum GetTrainingByIdResponseError {
}
export async function getTrainingById(params: GetTrainingByIdRequestParams, output?: RequestOutput<GetTrainingByIdResponse, GetTrainingByIdResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetTrainingById,
    params: params,
    output: output,
  })
}



export type CreateTrainingRequestParams = {
  trainingStatusId: number
  pharmacyId: number
  assigneeId: number | null
}
export type CreateTrainingResponse = {
  training: API_Training
}
enum CreateTrainingResponseError {
}
export async function createTraining(params: CreateTrainingRequestParams, output?: RequestOutput<CreateTrainingResponse, CreateTrainingResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateTraining,
    params: params,
    output: output,
  })
}



export type BulkCreateTrainingRequestParams = {
  trainingStatusId: number
  pharmacyIds: number[]
  assigneeId: number | null
}
export type BulkCreateTrainingResponse = {
  training: API_Training
}
enum BulkCreateTrainingResponseError {
}
export async function bulkCreateTraining(params: BulkCreateTrainingRequestParams, output?: RequestOutput<BulkCreateTrainingResponse, BulkCreateTrainingResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.BulkCreateTraining,
    params: params,
    output: output,
  })
}



export type UpdateTrainingRequestParams = {
  id: number
  adhesionNumber?: number
  trainingStatusId?: number
  pharmacyId?: number
  assigneeId?: number
  notes?: string
}
export type UpdateTrainingResponse = {
}
enum UpdateTrainingResponseError {
}
export async function updateTraining(params: UpdateTrainingRequestParams, output?: RequestOutput<UpdateTrainingResponse, UpdateTrainingResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateTraining,
    params: params,
    output: output,
  })
}



export type DeleteTrainingRequestParams = {
  id: number
}
export type DeleteTrainingResponse = {
}
enum DeleteTrainingResponseError {
}
export async function deleteTraining(params: DeleteTrainingRequestParams, output?: RequestOutput<DeleteTrainingResponse, DeleteTrainingResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteTraining,
    params: params,
    output: output,
  })
}