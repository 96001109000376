import React, { useState, useEffect } from 'react'
import clone from 'lodash/cloneDeep';
import { Card, Grid } from '@material-ui/core'
import { CardField, CardGrid, CardSubtitle, CardHeader, CardContent, FieldsListErrors, PageEntityDetailsRefreshableProps, useLocalization } from 'components'
import { Pharmacy, PharmacyLevel } from 'app/entities/types'
import { toast, useEffectAfter } from 'app/utils'
import {
	updatePharmacy, UpdatePharmacyRequestParams,
	GetAllPharmacyLevelsRequestParams, GetAllPharmacyLevelsResponse, getAllPharmacyLevels
} from 'app/api'
import { processFieldsErrors } from 'components/_CardPartials/CardField/methods'
import { prepareForSave, shouldSave } from 'components/_CardPartials/CardContent/methods'
import { userCan } from 'app/entities/methods'
import { UserPermissionValue } from 'app/values'
import { useSelector } from 'react-redux';
import { RootState } from 'app/session/store';

enum FieldName {
	Name = 'name',
	Address = 'address',
	City = 'city',
	State = 'state',
	Country = 'country',
	Phone = 'phone',
	Email = 'email',
	Zip = 'zip',
	Vat = 'vat',
	Pec = 'pec',
	Sdi = 'sdi',
	Area = 'area',
	PharmacyContact = 'pharmacyContact',
	SapCode = 'sapCode'
}

type DataProps = {
	[FieldName.Name]?: string
	[FieldName.Address]?: string
	[FieldName.City]?: string
	[FieldName.State]?: string
	[FieldName.Country]?: string
	[FieldName.Phone]?: string
	[FieldName.Email]?: string
	[FieldName.Zip]?: string
	[FieldName.Vat]?: string
	[FieldName.Pec]?: string
	[FieldName.Sdi]?: string
	[FieldName.Area]?: string
	[FieldName.PharmacyContact]?: string
	[FieldName.SapCode]?: string
}

interface InfoProps extends PageEntityDetailsRefreshableProps {
	object: Pharmacy,
}

const Info = ({ ...props }: InfoProps) => {
	const { t } = useLocalization()
	const session = useSelector((state: RootState) => state.session)

	const [isLoading, setIsLoading] = useState(false)

	const canManagePharmacies = userCan(session.user, UserPermissionValue.PharmaciesManagement) === true


	//OBJECT

	const [data, setData] = useState<DataProps>({
		[FieldName.Name]: props.object.name,
		[FieldName.Address]: props.object.address,
		[FieldName.City]: props.object.city,
		[FieldName.State]: props.object.state,
		[FieldName.Country]: props.object.country,
		[FieldName.Phone]: props.object.phone,
		[FieldName.Email]: props.object.email,
		[FieldName.Zip]: props.object.zip,
		[FieldName.Vat]: props.object.vat,
		[FieldName.Pec]: props.object.pec,
		[FieldName.Sdi]: props.object.sdi,
		[FieldName.Area]: props.object.area,
		[FieldName.PharmacyContact]: props.object.pharmacyContact,
		[FieldName.SapCode]: props.object.sapCode,
	})
	const [currentData, setCurrentData] = useState(data)
	const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

	function updateData(data: DataProps) {
		setIsUpdatingCurrentData(true)
		setData(data)
		setCurrentData(data)
	}

	useEffect(() => {
		setIsUpdatingCurrentData(false)
	}, [currentData])

	useEffect(() => {
		const initialized = props.object != null
		setIsLoading(!initialized)
	}, [props.object])



	//ERROR HANDLING

	const errorsConditions: FieldsListErrors = {
		// [FieldName.Name]: [
		// 	{ error: data.name === '', errorMessage: 'Il nome del prodotto non può essere vuoto' },
		// ],
	}

	const [errors, setErrors] = useState<FieldsListErrors>({})

	useEffect(() => {
		setErrors(processFieldsErrors(FieldName, errorsConditions))
	}, [data])



	//FIELD UPDATES

	function updateFieldValue(name: string, value: any) {
		const temp = clone(data)

		switch (name) {
			case FieldName.Name: temp.name = value; break
			case FieldName.Address: temp.address = value; break
			case FieldName.City: temp.city = value; break
			case FieldName.Zip: temp.zip = value; break
			case FieldName.State: temp.state = value; break
			case FieldName.Country: temp.country = value; break
			case FieldName.Phone: temp.phone = value; break
			case FieldName.Email: temp.email = value; break
			case FieldName.Vat: temp.vat = value; break
			case FieldName.Pec: temp.pec = value; break
			case FieldName.Sdi: temp.sdi = value; break
			case FieldName.Area: temp.area = value; break
			case FieldName.PharmacyContact: temp.pharmacyContact = value; break
			case FieldName.SapCode: temp.sapCode = value; break
			default: break
		}

		setData(temp)
	}

	function confirmFieldValue(name: string, value: any) {
		save(name as FieldName)
	}



	//SAVE

	function save(name?: FieldName) {
		if (shouldSave({ isUpdatingData: isUpdatingCurrentData, fieldName: name, fieldParameters: errorsConditions, data: data, currentData: currentData }) === false) return
		prepareForSave({ fieldName: name, data: data, currentData: currentData, updateDataCallback: setCurrentData })

		savePharmacyObject()

		function savePharmacyObject() {
			const encode = (): UpdatePharmacyRequestParams => {
				return {
					id: props.object.id,
					name: data.name,
					address: data.address,
					city: data.city,
					state: data.state,
					country: data.country,
					phone: data.phone,
					email: data.email,
					zip: data.zip,
					vat: data.vat,
					pec: data.pec,
					sdi: data.sdi,
					mq: data.area,
					pharmacyContactName: data.pharmacyContact,
					sapCode: data.sapCode,
				}
			}

			updatePharmacy(encode(), {
				response(data) {
					toast.success('Farmacia aggiornata con successo')
					if (props.refreshObject != null) props.refreshObject()
				},
				error(error, message) {
					toast.error(message)
				}
			})
		}
	}



	//LEVELS

	const [levels, setLevels] = React.useState<{ key: string, value: number, name: string }[] | null>(null)
	const [selectedLevel, setSelectedLevel] = React.useState<PharmacyLevel | null>(props.object.pharmacyLevel ?? null)

	useEffect(() => {
		fetchLevels()
	}, [])

	function fetchLevels() {
		setIsLoading(false)

		const encode = (): GetAllPharmacyLevelsRequestParams => {
			let req: any = {
			}
			return req
		}

		const decode = (data: GetAllPharmacyLevelsResponse): PharmacyLevel[] => {
			let object: PharmacyLevel[] = []
			data.pharmacyLevels.map((model, i) => {
				object.push({
					id: model.id,
					name: model.name,
					keyword: model.keyword,
				})
			})
			return object
		}

		getAllPharmacyLevels(encode(), {
			response(data) {
				setIsLoading(false)
				const items = decode(data)
				let opt: any = []
				items.forEach((m, i) => {
					opt.push({ key: i, value: m, name: m.name })
					if (m.id === props.object.pharmacyLevel?.id) setSelectedLevel(m)
				})
				setLevels(opt)
			},
			error(error, message) {
				setIsLoading(false)
				toast.error(message)
			}
		})
	}

	useEffect(() => {
		const initialized = levels != null
		setIsLoading(!initialized)
	}, [levels])

	function selectLevel(name: string, level: PharmacyLevel | null) {
		setSelectedLevel(level)
	}

	useEffectAfter(() => {
		if (selectedLevel == null) return
		if (selectedLevel.id === props.object.pharmacyLevel?.id) return
		updatePharmacyObject({
			id: props.object.id,
			levelId: selectedLevel.id
		})
	}, [selectedLevel])

	function updatePharmacyObject(params: UpdatePharmacyRequestParams) {
		updatePharmacy(params, {
			response(data) {
				toast.success('Farmacia aggiornata con successo')
				if (props.refreshObject != null) props.refreshObject()
			},
			error(error, message) {
				toast.error(message)
			}
		})
	}



	//RENDER

	return (
		<Card>
			<CardHeader title={'Dati farmacia'} isLoading={isLoading} />
			<CardContent isLoading={isLoading}>

				<Grid container spacing={3}>
					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.Name} disabled={!canManagePharmacies}
							label={'Nome'} placeholder={'Inserisci un nome...'}
							value={data.name} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.Vat} disabled={!canManagePharmacies}
							label={'Partita IVA'} placeholder={'Inserisci una partita IVA...'}
							value={data.vat} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.Address} disabled={!canManagePharmacies}
							label={'Indirizzo'} placeholder={'Inserisci un indirizzo...'}
							value={data.address} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.City} disabled={!canManagePharmacies}
							label={'Città'} placeholder={'Inserisci una città...'}
							value={data.city} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.State} disabled={!canManagePharmacies}
							label={'Provincia'} placeholder={'Inserisci una provincia...'}
							value={data.state} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6} md={3}>
						<CardField type={'text'} name={FieldName.Zip} disabled={!canManagePharmacies}
							label={'CAP'} placeholder={'Inserisci un CAP...'}
							value={data.zip} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6} md={3}>
						<CardField type={'text'} name={FieldName.Country} disabled={!canManagePharmacies}
							label={'Stato'} placeholder={'Inserisci uno stato...'}
							value={data.country} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>

					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.Phone} disabled={!canManagePharmacies}
							label={'Telefono'} placeholder={'Inserisci un telefono...'}
							value={data.phone} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>
					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.Email} disabled={!canManagePharmacies}
							label={'Email'} placeholder={'Inserisci uno stato...'}
							value={data.email} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>

					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.PharmacyContact} disabled={!canManagePharmacies}
							label={'Responsabile farmacia'} placeholder={'Inserisci un responsabile farmacia...'}
							value={data.pharmacyContact} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>

					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.Area} disabled={!canManagePharmacies}
							label={'Superficie (metri quadri)'} placeholder={'Inserisci una superficie...'}
							value={data.area} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>

					<CardGrid item xs={12} sm={6}>
						<CardField type={'text'} name={FieldName.SapCode} disabled={!canManagePharmacies}
							label={'Codice SAP'} placeholder={'Inserisci un codice...'}
							value={data.sapCode} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</CardGrid>

					<CardGrid item xs={12} sm={6}>
						<CardField type={'select'} label={'Livello'} name={'level'}
							value={selectedLevel ?? ''}
							options={levels ?? []}
							onUpdate={selectLevel}
						/>
					</CardGrid>
				</Grid>

			</CardContent>
		</Card>
	)
}

export default Info